import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`There are two levels of content.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`1) Is the activity itself. For example the descend on a singletrack for MTB.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2.1) Is the whole tour including the uphill on gravel road, the descend on
singletrack, and ride back to the start on tarmac road.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2.2) or the access to the start of an activity.`}</p>
      </li>
    </ul>
    <br />
    <h3>{`Level 1`}</h3>
    <br />
    <h4>{`MTB Singletrack`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Typically a narrow path below 1,5m width. It can be also be
  a purpose built mountain bike track. It is not limited to a certain type of
  bike. It can be suitable for XC, Enduro, and DH bikes. Can be uphill,
  downhill or both.
  <br /><br />
  <b>Different way types:</b><br />
  If the track contains short parts of gravel road - for
  example to connect two singletracks - it is up to you if you split the
  track into two or not. The ride, should be as continuous as possible without
  creating too many individual trails. The connections on no singletrack should
  be very short though (max. 10-20% of the singletrack).
  <br /><br />
  <b>Loops:</b><br />
  Only if all parts are on singletracks (access, climb, descend,
  flat parts). If not, cut the track to the parts on singletrack. You still
  can show the whole tour including the access and climbs: Therefore see
  paragraph “d” below.
    </div>
    <br />
    <h4>{`MTB Cross-Country/Gravel`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Mix of all kinds of terrain. Singletrack, gravel, tarmac roads. Separates
  from the "Tour cycling" category through it's sportive character and
  technical demands. Can be loops (whole tours)
    </div>
    <br />
    <h4>{`MTB Fatbike`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Biking on snow. Tracks may not exist in summer.
    </div>
    <br />
    <h4>{`Pumptrack`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Parkour for bikes, skateboards, scooters. Waves and banked turns allow to
  gain speed just by pushing & pulling (pumping) the sport device up and down.
    </div>
    <br />
    <h4>{`Dirt jumps`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Jumps in all variations for all kinds of skill levels.
    </div>
    <br />
    <h4>{`Road biking`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Cycling on tarmac roads. The focus is more sportive than Tour Cycling,
  to overcome a certain distance or climb.
    </div>
    <br />
    <h4>{`Tour Cycling`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Focus is on being outdoors, enjoying nature, sightseeing, and easy
  pleasure. It's not about sportive performance, and accomplishing technical
  difficult routes. The bicycles are made for sealed roads. There can be parts
  on compact gravel roads or narrow tracks that have no technical challenges.
    </div>
    <br />
    <h4>{`Hiking`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  Walking activity with or without technical demands. Usually on gravel roads
  or narrow paths.
    </div>
    <br /><br />
    <h3>{`Level 2`}</h3>
    <br />
    <h4>{`Tour`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  A whole tour from A to A or A to B. Must have one main activity category,
  so it can be related to it.
    </div>
    <br />
    <h4>{`Access`}</h4>
    <div className="max-w-xl p-4 bg-gray-200">
  How to get to the start of an activity or to a location. A to B. Must have
  one main activity category, so it can be related to it.
    </div>
    <br />
    <br />
    <Link className="float-left" to="/pro/upload" mdxType="Link">
  ← How to upload an activity
    </Link>
    <Link className="float-right" to="/pro/gpstrack" mdxType="Link">
  GPS track file (.gpx) →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      